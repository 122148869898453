import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import ReturnServiceImg from '../../../images/return_service.jpg'

const paginaServizio = {
  titolo: 'Return Service',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: ReturnServiceImg,
      paragrafi: [
        {
          id: 1,
          testo:
            "ReturnService si rivolge a tutti coloro che vendono online e hanno la necessità di gestire i propri resi in maniera semplice e veloce. Sarà sufficiente richiedere a GLS l'abilitazione del servizio e decidere il luogo di destino della merce resa, che potrà non coincidere con la località di partenza. Le spedizioni di partenza saranno create secondo le modalità abituali. Quando il Destinatario richiede il reso della merce, si potrà procedere in due modi:",
        },
        {
          id: 2,
          testo: '- prenotare il ritiro del reso direttamente a GLS',
        },
        {
          id: 3,
          testo:
            "- consentire al Destinatario l'accesso ad una pagina dedicata del sito GLS, in cui prenotare il ritiro del reso o scegliere l'opzione drop-off presso una qualsiasi filiale GLS o GLS Shop, su tutto il territorio nazionale.",
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            "Nessuna necessità di inserire nella spedizione di partenza un'etichetta per gestire il reso",
        },
        {
          id: 2,
          testo:
            'Notifiche al destinatario relative alla prenotazione del ritiro/drop-off e alla spedizione di reso',
        },
        {
          id: 3,
          testo:
            'Stampa della conferma di ritiro/drop-off consigliata al Destinatario, ma non obbligatoria',
        },
        {
          id: 4,
          testo:
            "Tracciabilità della merce, dall'andata al reso passando per la richiesta di ritiro",
        },
        {
          id: 5,
          testo: "Consegna della merce resa all'indirizzo prescelto",
        },
        {
          id: 6,
          testo:
            'Possibilità di rendere il proprio Destinatario autonomo nella gestione del reso, consentendogli sia il ritiro a domicilio sia il drop-off',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
      ],
      servizi: null,
    },
  ],
}

const ReturnService = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default ReturnService
